import React from "react";
import { Row, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Table from "../../../../layout/table";
import { format as formatNumber } from "../../../../utils/number";
import { table as getEmployees } from "../../../../actions/employee";
import { deepEqual } from "../../../../utils/object";

class Staff extends React.Component
{
    constructor(props) 
    {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "lastName",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            archived: 0,
            converted: 0,
            assigned: this.props.assigned || []
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.fetchData();
        }, 30);
    }

    componentDidUpdate = (old) => {
        if (!deepEqual(this.props.assigned, old.assigned)) {
            this.setState({
                ...this.state,
                assigned: this.props.assigned || []
            })
        }
    }

    fetchData = async () => {
        const __data = await this.props.getEmployees(
            {
                page: this.state.page,
                size: this.state.size,
                order: this.state.order,
                direction: this.state.direction, 
                search: this.state.search
            });
        let __count = 0;

        if ((__data) && (__data.length || 0 > 0))
            __count = Math.ceil(Number(__data[0].count) / Number(this.state.size));

        this.setState({
            ...this.state,
            data: __data,
            pages: __count
        });
    }

    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);
    }


    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }

    postChange = async (e) => {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    get headers() {
        return [
            {
                header: "Employee",
                sortable: true,
                source: "lastName",
                className: "text-muted text-small text-uppercase w-35",
                onRender: this.onEmployeeFormat
            },
            {
                header: "Rate",
                source: "rate",
                className: "text-muted text-small text-uppercase w-25",
                onRender: this.onRateFormat
            }
        ]
    }

    onEmployeeFormat = (e, row) => {
        let __current = false;
        for (let i = 0; i < this.state.assigned.length; i++) {
            const item = this.state.assigned[i];
            if ((item.employeeUuid === row["uuid"]) && (new Date(item.startDate) < Date.now()) && (!item.endDate)) {
                __current = true;
            }
        }

        return (<>
            <span>{row["Person.lastName"]}, {row["Person.firstName"]} ({row["title"]})</span>
            <a role="button" className={`badge rounded-pill bg-${__current ? "success" : "danger"}`} style={{ marginLeft: "5px" }} onClick={(e) => {
                this.onChange({
                    uuid: row["uuid"],
                    assigned: !__current,
                    rate: row["rate"],
                    type: "assignment"
                })
            }}>{__current ? "ASSIGNED" : "UNASSIGNED"}</a>
        </>);
    }

    onRateFormat = (e, row) => {
        let __rate = row["rate"];
        for (let i = 0; i < this.state.assigned.length; i++) {
            const item = this.state.assigned[i];
            if ((item.employeeUuid === row["uuid"]) && (new Date(item.startDate) < Date.now()) && (!item.endDate)) {
                __rate = item.rate;
            }
        }
        
        return (<>
            <NumberFormat
                displayType="input"
                type="text"
                id={`rate-${row["uuid"]}`}
                name="rate"
                className="text-right form-control mb-2"
                prefix={"$"}
                thousandSeparator={true}
                allowNegative={false}
                value={__rate}
                onValueChange={(e) =>
                    this.onChange({
                        uuid: row["uuid"],
                        rate: e.floatValue,
                        type: "rate"
                    })
                }
                removeFormatting
            /></>);
    }


    onChange = async (e) => {
        if (e.type === "rate") {
            if (this.props.onRateChange) this.props.onRateChange({
                uuid: e.uuid,
                rate: e.rate
            });
        } else if (e.type === "assignment") {
            if (this.props.onAssignmentChange) this.props.onAssignmentChange({
                uuid: e.uuid,
                assigned: e.assigned,
                rate: e.rate
            });
        }
        await this.componentDidMount();

    }

    render() {
        return (
            <Row className="g-3">
                <Col xs="12">
                    <h2 className="small-title">Assignment</h2>
                </Col>
                <Col xs="12">
                    <Table
                        headers={this.headers}
                        page={this.state.data}
                        sort={{ index: this.state.index, direction: this.state.direction }}
                        size={this.state.size}
                        index={this.state.page}
                        pages={this.state.pages}
                        onColumnClick={this.onColumnClick}
                        onPageChange={this.onPageChange}
                        onSearch={this.onSearch}
                        onSize={this.onSize}
                        searchable
                        sizable
                    />
                </Col>
            </Row>
        )
    }
}

Staff.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { getEmployees })(Staff);
