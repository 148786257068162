import React from "react";
import { connect } from "react-redux";
import { Form, Card, Col, Row, Tab, Nav } from 'react-bootstrap';
import PropTypes from "prop-types";

import ConditionalButton from "../../layout/conditional-button";
import ConditionalDropdown from "../../layout/conditional-dropdown";
import Logs from "../../layout/logs";
import Event from "../../layout/event";
import Multinput from "../../layout/multinput";

import { deepEqual } from "../../utils/object";
import { isGuid } from "../../utils/string";
import icalTk from "../../utils/ics";
import { JavaScripttoXML } from "../../utils/serialize";
import { build, send } from "../../utils/email";

import Personal from "./person";
import Calendar from "./calendar";

import { get as getEmployee, save as saveEmployee } from "../../actions/employee";
import { pack as setAlerts } from "../../actions/alert";
import { activities as getActivities } from "../../actions/code";
import { save as saveEvent, assigned as getEvents, destroy as deleteEvent } from "../../actions/event";
import { list as getLogs, update as saveLog } from "../../actions/log";
import { list as getAttorneys } from "../../actions/attorneys";
import { list as getReports, get as getReport, docx as generateDocx, pdf as generatePdf, xsl as generateXsl, named } from "../../actions/report";

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            person: {},
            logs: [],
            selected: "profile",
            modal: {
                event: {
                    show: false
                },
                multinput: {
                    show: false
                }
            },
            Codes: {
                activities: [],
                attorneys: [],
                reports: []
            }
        };
        
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(oldProps.person, this.props.person) && !deepEqual(this.props.person, this.state.person)) {
            this.setState({
                ...this.state,
                person: this.props.person
            });
        }
    }

    componentDidMount = async () => {
        setTimeout(() => {
            this.loadData(); 
        }, 30);
    }

    loadData = async () => {
        await this.props.getEmployee();
        const activities = await this.props.getActivities("ALL");
        const attorneys = await this.props.getAttorneys();
        const logs = await this.props.getLogs(this.props.auth.user.uuid);
        const reports = await this.props.getReports({ category: "Profile" });

        for (let i = 0; i < reports.length; i++) {
            reports[i].criteria = JSON.parse(reports[i].criteria);
        }

        this.setState({
            ...this.state,
            logs,
            Codes: {
                ...this.state.Codes,
                activities,
                attorneys,
                reports
            }
        });
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    }

    onPersonChange = (value, errors) => {
        this.setState({
            ...this.state,
            person: {
                ...value
            },
            errors: errors
        });
    }

    onSaveClick = async (e) => {
        const result = await this.props.saveEmployee({ uuid: this.state.person.uuid, Person: this.state.person });
        if (result?.success) {
            this.setState({
                person: result.person
            })
        }
    }

    onShowErrors = (e) => {
        const __messages = [];
        this.state.errors.forEach((error) => {
            __messages.push(error.errors[0]);
        })
        this.props.setAlerts(__messages, "error");
    }

    
    onEventSelect = async (e) => {
        const __event = await this.props.getEvents(null, null, this.props.auth.user.uuid, e);
        if (__event && __event.length) {
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    event: {
                        ...this.state.modal.event,
                        selected: __event[0],
                        caption: "Edit Event",
                        show: true
                    }
                }
            });
        }
    }

    onEventOk = async (e) => {
        this.onEventHideModal(e);
        e.subjectUuid = this.state.uuid;
        e.path = "leads";
        const event = await this.props.saveEvent(e);

        if (e.assignedToUuid && e.send) {
            const __employee = this.state.Codes.attorneys.find(option => option.uuid === e.assignedToUuid);
            if (__employee) {
                const ics = new icalTk();
                ics.method = "REQUEST";
                ics.events.push({
                    start: new Date(Date.parse(e.startDate)),
                    end: new Date(Date.parse(e.endDate)),
                    transp: "OPAQUE",
                    summary: e.caption,
                    alarms: [15],
                    uid: e.uuid,
                    method: "REQUEST",
                    status: "CONFIRMED"
                });
                this.sendEventMail(__employee.Person.email, event, ics.toString());
            }
        }

        this.setState({
            ...this.state,
            event: {
                ...this.state.event,
                updated: Date.now()
            }
        });
    }

    onEventDelete = async (e,) => {
        this.onEventHideModal(e);
        if (e.assignedToUuid && e.send) {
            const __employee = this.state.Codes.attorneys.find(option => option.uuid === e.assignedToUuid);
            const event = await this.props.getLeadEvents(this.state.uuid, null, null, e.uuid);

            if (__employee) {
                const ics = new icalTk();
                ics.method = "REQUEST";
                ics.events.push({
                    start: new Date(Date.parse(e.startDate)),
                    end: new Date(Date.parse(e.endDate)),
                    transp: "OPAQUE",
                    summary: e.caption,
                    alarms: [15],
                    uid: e.uuid,
                    method: "REQUEST",
                    status: "CANCELLED"
                });
                this.sendEventMail(__employee.Person.email, event, ics.toString());
            }
        }

        await this.props.deleteEvent({ uuid: e.uuid, subjectUuid: this.state.uuid, path: "leads" });

        this.setState({
            ...this.state,
            event: {
                ...this.state.event,
                updated: Date.now()
            }
        });
    }

    onEventHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                event: {
                    ...this.state.modal.event,
                    show: false
                }
            }
        })
    }

    onReportParametersOk = async (e) => {
        this.onReportParametersHideModal(e);
        const __report = this.state.modal.multinput.report;
        __report.settings = e;
        this.onReportClick(null, __report);
    }

    onReportParametersHideModal= (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                multinput: {
                    ...this.state.modal.multinput,
                    show: false
                }
            }
        });
    }

    onRecordEvent = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                event: {
                    ...this.state.modal.event,
                    caption: null,
                    show: true,
                    selected: {
                        assignedToUuid: this.props.auth.user.uuid
                    },
                    display: {
                        person: false,
                        assignment: false
                    }
                }
            }
        })
    }

    sendEventMail = async (toEmail, event, icsString) => {
        setTimeout(async () => {            
            const __fileName = `invite_${event.uuid}.ics`;
            const _xml = JavaScripttoXML(event); 
            const built = await build(_xml, "EVENT.SEND");
            await send(built.from, toEmail, built.subject, built.html, [{ filename: __fileName, encoding: 'utf8', content: icsString }]);
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    event: {
                        ...this.state.modal.event,
                        updated: Date.now()
                    }
                }
            })
        }, 30);
    }

    onTabChange = (e) => {
        this.setState({
            ...this.state,
            selected: e
        });
    }

    
    onLogSave = async (e) => {
        e.targetUuid = this.props.auth.user.uuid;
        e.sourceCode = "ME";
        e.addDate = Date.now();
        await this.props.saveLog(e);
        const __logs = this.state.logs;
        __logs.push({
            ...e,
            Person: {
                firstName: this.props.auth.user.firstName,
                lastName: this.props.auth.user.lastName
            }
        });

        this.setState({
            ...this.state,
            logs: __logs
        })
    }

    onPreReportClick = async (e, data) => {
        const _report = this.state.Codes.reports.find((e) => e.name === data.name);
        if (!_report?.criteria?.fields?.length) {
            this.onReportClick(e, data);
            return;
        }

        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                multinput: {
                    ...this.state.modal.multinput,
                    fields: _report?.criteria?.fields,
                    report: _report,
                    show: true
                }
            }
        });
    }

    onReportClick = async (e, data) => {
        let _data_object = {};
        const _report = this.state.Codes.reports.find((e) => e.name === data.name);
        switch (_report.criteria.dataset) {
            case "EVENTS":
                const __events = await this.props.getEvents(data.settings.startDate, data.settings.endDate, this.props.auth.user.uuid, e);
                data.settings.tsStartDate = new Date(data.settings.startDate);
                data.settings.tsEndDate = new Date(data.settings.endDate);

                _data_object.events = __events;
                _data_object.user = this.props.auth.user;
                _data_object.settings = data.settings;
                break;
        
            default:
                break;
        }

        const _xml = JavaScripttoXML(_data_object);
        if (data.outputCode === "RTF") {
            const _result = await this.props.generateDocx(_xml, data.uuid);
            const url = window.URL.createObjectURL(new Blob([_result]));//, { type: 'application/zip' }));
            const link = document.createElement('a');
            const fileName = await this.props.named(data.fileName, _xml);
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } else if (data.outputCode === "PDF") {
            const _result = await this.props.generatePdf(_xml, data.uuid);
            const url = window.URL.createObjectURL(new Blob([_result]));//, { type: 'application/zip' }));
            const link = document.createElement('a');
            const fileName = await this.props.named(data.fileName, _xml);
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }

    get reports() {
        const result = [];
        this.state.Codes.reports.forEach((element, index) => {
            result.push({
                onClick: this.onPreReportClick,
                caption: element.caption,
                uuid: element.uuid,
                outputCode: element.outputCode,
                name: element.name,
                category: element.category,
                fileName: element.fileName,
                index 
            });
        });
        
        return result;
    }

    get hasReports() {
        return this.reports?.length > 0;
    }

    render() {
        return (
            <>
                {/* Title End */}
                <Col md="8" className="float-right text-end">
                    <ConditionalButton enabledVariant="outline-primary" tooltip="Save my information" display={true} enabled={true} onEnabledClick={this.onSaveClick} icon="save">Save</ConditionalButton>
                    <ConditionalDropdown className="ms-sm-1" enabledVariant="secondary" enabled display={this.hasReports} items={this.reports}>Report</ConditionalDropdown>
                </Col>
                
                <Tab.Container activeKey={this.state.selected}>
                    <Card.Header className="border-0 pb-0" style={{backgroundColor: "white"}}>
                        <Nav className="nav-tabs-line card-header-tabs" variant="tabs" activeKey={this.state.selected} style={{backgroundColor: "#f9f9f9"}}>
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#profile"
                                    className={`${this.state.selected === 'profile' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('profile');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Profile
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#calendar"
                                    className={`${this.state.selected === 'calendar' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('calendar');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Calendar
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="text-center">
                                <Nav.Link
                                    href="#notes"
                                    className={`${this.state.selected === 'notes' && 'active'}`}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        this.onTabChange('notes');
                                    }}
                                    style={{ paddingTop: "25px", paddingBottom: "25px" }}
                                >
                                    Notes
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Card.Header>
                    <Col md="12">
                        <Form name="frm" action="post" onSubmit={this.onSubmit} >
                            <Row>
                                <Col>
                                    <Tab.Content className=" h-100">
                                        <Tab.Pane active={this.state.selected === 'profile'} className="h-100 scroll-out">
                                            <Card body className="mb-5">
                                                <Personal {...this.state.person} onChange={this.onPersonChange} />
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane active={this.state.selected === 'calendar'} className="h-100 scroll-out">
                                            {this.state.selected === 'calendar' ? (
                                                <Card body className="mb-5">
                                                    <Calendar lead={this.state.uuid} onCreate={this.onRecordEvent} onSelect={this.onEventSelect} updated={this.state.event?.updated}></Calendar>
                                                </Card>
                                            ) : ``}
                                        </Tab.Pane>
                                        <Tab.Pane active={this.state.selected === 'notes'} className="h-100 scroll-out">
                                            <Card body className="mb-5">
                                                <Logs logs={this.state.logs} onSave={this.onLogSave} display={{ priority: false }} />
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Tab.Container>
                <Event
                    show={this.state.modal.event.show}
                    onDelete={this.onEventDelete}
                    onCancel={this.onEventHideModal}
                    onOk={this.onEventOk}
                    activities={this.state.Codes.activities}
                    employees={this.state.Codes.attorneys}
                    title={this.state.modal.event.caption}
                    display={{ person: this.state.modal.event.selected?.Client, assignment: false }}
                    {...this.state.modal.event.selected}
                />
                <Multinput
                    show={this.state.modal.multinput.show}
                    onCancel={this.onReportParametersHideModal}
                    onOk={this.onReportParametersOk}
                    title={this.state.modal.multinput?.caption}
                    fields={this.state.modal.multinput?.fields}
                />
            </>
        )
    }
}

Profile.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    person: PropTypes.object,
    employee: PropTypes.object,
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        fields: state.fields.items,
        person: state.person.person,
        employee: state.person.employee
    });
};

export default connect(mapStateToProps, { getEmployee, getAttorneys, saveEmployee, getEvents, setAlerts, saveEvent, deleteEvent, getActivities, getLogs, saveLog, getReports, getReport, generateDocx, generatePdf, generateXsl, named })(Profile);
