import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';

import { assigned as getAssignedEvents } from "../../../../actions/event";
import ConditionalButton from "../../../../layout/conditional-button";

class Display extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedView: props.selectedView || "dayGridMonth",
            dateTitle: props.dateTitle || "",
            events: []
        }
        this.calendarRef = React.createRef();
        this.doubleClick = null;
        this.clickTimer = null;
    }

    componentDidMount = () => {
        this.__isMounted = true;
        const __currentRange = this.calendarRef.current.getApi().currentDataManager.state.dateProfile.activeRange;

        setTimeout(() => {
            this.handleDates({
                startStr: __currentRange.start,
                endStr: __currentRange.end
            });
        }, 30);
    }

    componentDidUpdate = (oldProps) => {
        if (oldProps.updated !== this.props.updated) {
            this.componentDidMount();
        }
    }


    componentWillUnmount = () => {
        this.__isMounted = false;
    }


    renderCalendar = () => {
        this.calendarRef.current.getApi().updateSize();
    }

    handleDates = async (rangeInfo) => {
        if (!this.__isMounted) return;

        try {
            const events = await this.props.getAssignedEvents(Date.parse(rangeInfo.startStr), Date.parse(rangeInfo.endStr), this.props.assignedTo);
            const eventData = [];

            for (let i = 0; i < events.length; i++) {
                const item = events[i];

                eventData.push({
                    id: item.uuid,
                    title: item.caption,
                    start: Date.parse(item.startDate),
                    end: Date.parse(item.endDate),
                    backgroundColor: item.Activity.backgroundColor || "inherit",
                    borderColor: item.Activity.borderColor || "inherit",
                    category: item.category,
                    locationUuid: item.locationUuid,
                    activityUuid: item.activityUuid
                });
            }
            this.setState({
                ...this.state,
                events: eventData
            }, this.renderCalendar)
        } catch (e) {
            console.log('This action could not be completed');
        }
    }

    handleDoubleClick = async (e) => {
        if (this.props.onSelect) this.props.onSelect(e);
    }

    onClearTimer = () => {
        this.doubleClick = null;
        clearInterval(this.clickTimer);
    }

    onDateClick = (e) => {
        if (this.doubleClick) {
            this.doubleClick = null;
            if (this.props.onCreate) this.props.onCreate({ startDate: e.dateStr, endDate: e.dateStr });
        } else {
            this.doubleClick = new Date();
            clearInterval(this.clickTimer);
            this.clickTimer = setInterval(this.onClearTimer, 500);
        }
    }


    viewDidMount = ({ view }) => {
        this.setState({
            ...this.state,
            dateTitle: view.title
        });
    }

    
    renderEventContent = (eventInfo) => {
        const { timeText, backgroundColor, borderColor } = eventInfo;
        const { allDay, title, id } = eventInfo.event;
        if (!allDay) {
            return (
                <div onDoubleClick={() => this.handleDoubleClick(id)} style={{verticalAlign: "middle"}}>
                    <span className="fc-daygrid-event-dot" style={{ display: "inline-block", backgroundColor, borderColor }} />
                    <span className="fc-event-time" style={{}}>{timeText}</span>
                    <span className="fc-event-title" style={{}}>{title}</span>
                </div>
            );
        }
        return (
            <>
                <div className="fc-event-main-frame">
                    <div className="fc-event-title-container">
                        <div className="fc-event-title fc-sticky">{title}</div>
                    </div>
                </div>
            </>
        );
    }


    render() {
        return (

            <>
                <div style={{position: "absolute", top: "32px", left: "115px"}}>
                    <ConditionalButton
                        enabledVariant="outline-primary"
                        display
                        enabled
                        onEnabledClick={this.props.onCreate}
                        icon="plus">Create Event</ConditionalButton>
                </div>
                
                <FullCalendar
                    ref={this.calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin]}
                    headerToolbar={{
                        left: 'today',
                        right: 'prev,next',
                        center: 'title'
                    }}
                    initialView="dayGridMonth"
                    themeSystem="bootstrap"
                    editable
                    selectable
                    selectMirror
                    dayMaxEvents
                    weekends
                    dateClick={this.onDateClick}
                    datesSet={this.handleDates}
                    eventContent={this.renderEventContent}
                    viewDidMount={this.viewDidMount}
                    events={this.state.events}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false,
                    }}
                />
            </>
        )
    }
}

Display.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
    });
};

export default connect(mapStateToProps, { getAssignedEvents })(Display);
